import React, { Component } from "react";

const arrow = require("../img/right-arrow.png");

class Cta extends Component {
    render() {
        return (

            <a href="#"> <div className="cta__round"> <img id="arrow" className="arrow" src={arrow} alt="Buy"></img> <img id="arrow_hidden" className="arrow_hidden" src={arrow} alt="Buy"></img> </div> </a>

        );
    }
}

export default Cta;