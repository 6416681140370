const cardData = {
    packageTitle: "Basic",
    packageDescription: "This package is perfect to get you started.  Upgrade at any time.",
    packagePrice: "$250",
    packageTerm: "/ month",
    featureList1: "- Three Page Layout *",
    featureList2: "- Minimal Branding & Design *",
    featureList3: "- Search Engine Optimization",
    featureList4: "- $25/hr Support & Revisions"
};

export default cardData;
