import React, { Component } from "react";

class Body extends Component {
    render() {
        return (
            <section className="features">
                <ul className="features__list">
                    <li className="feature__item included"> {this.props.featureList1} </li>
                    <li className="feature__item included"> {this.props.featureList2} </li>
                    <li className="feature__item included"> {this.props.featureList3} </li>
                    <li className="feature__item included"> {this.props.featureList4} </li>
                </ul>
            </section>
        );
    }
}

export default Body;