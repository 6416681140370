import React, { Component } from "react";
import Head from "./Head";
import Body from "./Body";
import Cta from "./Cta";

class Card extends Component {
    render() {
        return (

            <div className="card" style={{ backgroundColor: "#252b37" }}>
                <section className="intro">
                    <Head
                        packageTitle={this.props.packageTitle}
                        packageDescription={this.props.packageDescription}
                        packagePrice={this.props.packagePrice}
                        packageTerm={this.props.packageTerm}>
                    </Head>
                </section>

                <section className="contents">
                    <Body
                        featureList1={this.props.featureList1}
                        featureList2={this.props.featureList2}
                        featureList3={this.props.featureList3}
                        featureList4={this.props.featureList4}>
                    </Body>
                </section>

                <section className="action">
                    <Cta></Cta>
                </section>

            </div>
        );
    }
}

export default Card;