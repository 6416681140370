import React, { Component } from 'react';
import Card from "./components/Card";
import cardData from "./cardData";
import './App.css';

class App extends Component {
  render() {
    return (

      <div className="App">
        <Card
          packageTitle={cardData.packageTitle}
          packageDescription={cardData.packageDescription}
          packagePrice={cardData.packagePrice}
          packageTerm={cardData.packageTerm}
          featureList1={cardData.featureList1}
          featureList2={cardData.featureList2}
          featureList3={cardData.featureList3}
          featureList4={cardData.featureList4}
          buttonText={cardData.buttonText}>
        </Card>
      </div>

    );
  }
}

export default App;
