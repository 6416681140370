import React, { Component } from "react";

class Head extends Component {
    render() {
        return (

            <div>
                <h2 className="package__title"> {this.props.packageTitle} </h2>

                <p className="package__desciption"> {this.props.packageDescription} </p>
                <div className="total__pricing">
                    <h1 className="package__price"> {this.props.packagePrice} </h1>
                    <p className="package__term">{this.props.packageTerm}</p>
                </div>
            </div>
        );
    }
}

export default Head;